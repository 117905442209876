export const lang = {
	"公司介绍": "Presentación",
	"品类介绍": "Categoría",
	"服务能力": "Capacidad de servicio",
	"需求反馈": "Retroalimentación de la demanda",
	"合作伙伴": "Socios",
	"联系我们": "Contáctanos",
	"全球优质供应链服务商": "Proveedor global de servicios de cadena de sumistro de alta calidad",
	"为全球品牌和企业提供定制化产品解决方案": "Proporcionar soluciones de productos personalizadas para marcas y empresas globales",
	"华汾公司介绍": "Introducción de Huafen (Beijing) International Trade Co., Ltd.",
	"aboutUs1": "Huafen (Beijing) International Trade Co., Ltd. es una empresa de comercio internacional que integra investigación y desarrollo de productos industriales, producción, diseño, marketing de marca global, ventas comerciales de importación y exportación, y basada en el núcleo tecnológico descentralizado de Web 3.0, a través de la gestión digital e inteligente de la red comercial global, ofrece servicios de marketing en internet y gestión digital de alta calidad para marcas y empresas.",
	"依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。": "Basándonos en los recursos avanzados de transporte marítimo de China y en la cobertura de 89 ciudades, 90 aeropuertos y más del 90% de las pantallas publicitarias de referencia en las ciudades, logramos una comunicación integral con los 1.400 millones de personas en China, aumentando la visibilidad de las marcas. Utilizamos los productos como medio, manteniendo la idea de progreso tecnológico, y ofrecemos a los consumidores globales productos de nueva generación innovadores, de alta calidad y con significado cultural internacional, presentando al mundo el nuevo desarrollo y la innovación del comercio chino.",
	"公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。": "La compañía posee tres series principales: marcas propias, marcas con licencia internacional y marcas de agencia. Además, cuenta con un sistema completo de cadena de suministro que abarca desde la investigación y desarrollo de productos, la producción, la venta, la logística hasta el marketing.",


	"company-profile-title1":"Huafen (Beijing) International Trade Co., Ltd. es",
	"company-profile1":"una empresa integral de comercio internacional que fusiona la investigación y el desarrollo de productos industriales, la producción, el diseño, la comercialización de marcas globales y las ventas de importación y exportación en una operación consistente. Nuestro enfoque único aprovecha la tecnología descentralizada Web 3.0 para proporcionar servicios de marketing en Internet y gestión digital de primer nivel. Nos destacamos en la preparación de estrategias digitales inteligentes a través de una red comercial global, brindando un valor excepcional a las marcas y empresas.",

	"company-profile-title2":"Ventaja estratégica: ",
	"company-profile2":"Aprovechamos las ventajas del transporte marítimo nacional y una sólida red publicitaria que abarca 89 ciudades y 90 aeropuertos, cubriendo efectivamente más del 90% de los puntos de referencia urbanos. Este amplio alcance nos permite conectarnos con la vasta población de China de 1.400 millones de personas, mejorando significativamente la visibilidad y el impacto de la marca.",

	"company-profile-title3":"Ofertas de productos innovadores: ",
	"company-profile3":"En Huafen, nos enorgullecemos de impulsar el avance tecnológico y la innovación. Introducimos productos de estilo de vida de alta calidad con importancia cultural internacional para los consumidores globales, mostrando el desarrollo comercial y las capacidades innovadoras de China en el escenario mundial.",

	"company-profile-title4":"Nuestra serie de productos:",
	"company-profile4":"Huafen gestiona tres series de productos distintas: ",


	"company-profile-title5":"1. Marcas propias:",
	"company-profile5":"marcas desarrolladas y propiedad de Huafen, que reflejan nuestro compromiso con la innovación y la calidad. ",

	"company-profile-title6":"2. Marcas autorizadas internacionalmente: ",
	"company-profile6":"marcas reconocidas a nivel mundial para las que tenemos derechos de distribución, lo que garantiza la calidad y la autenticidad. ",

	"company-profile-title7":"3. Marcas de agencia: ",
	"company-profile7":"marcas que representamos y comercializamos, aprovechando nuestra experiencia para expandir su alcance e impacto.",

	"company-profile-title8":"Cadena industrial integral: ",
	"company-profile8":"Nuestra empresa cuenta con una cadena industrial completa que integra investigación y desarrollo de productos, producción, ventas, logística y marketing. Este enfoque integrador garantiza operaciones sin interrupciones y un servicio excepcional, alineado con nuestro objetivo de ofrecer productos y soluciones superiores a nuestros clientes y socios en todo el mundo.",




	"我们能提供的品类": "Las categorías que podemos ofrecer",
	"个护清洁": "Cuidado personal y limpieza",
	"清洁工具": "Herramientas de limpieza",
	"家庭清洁": "Limpieza del hogar",
	"衣物清洁": "Limpieza de ropas",
	"洗发护发": "Lavado y cuidado del cabello",
	"身体护理": "Cuidado del cuerpo",
	"纸品湿巾": "Papeles y toallas húmedas",
	"口腔护理": "Cuidado bucal",
	"驱蚊驱虫": "Repelente de mosquitos e insectos",
	"食品酒饮": "Alimentos y bebidas",
	"乳饮冰品": "Leche y helados",
	"中外名酒": "Licores famosos chinos y extranjeros",
	"方便速食": "Comida conveniente",
	"粮油调味": "Cereales, aceites y condimentos",
	"咖啡": "Café",
	"休闲食品": "Snacks",
	"美妆护肤": "Maquillaje y cuidado de la piel",
	"面部护肤": "Cuidado facial",
	"香水": "Perfume",
	"彩妆": "Maquillaje",
	"家电": "Electrodomésticos",
	"家电配件": "Accesorios de electrodomésticos",
	"厨房小电": "Electrodomésticos de cocina",
	"清洁电器": "Electrodomésticos de limpieza",
	"个护健康": "Cuidado personal y salud",
	"空调": "Aire acondicionado",
	"冰箱": "Refrigerador",
	"电视": "Televisor",
	"厨卫大电": "Electrodomésticos de cocina y baño",
	"视听影音": "Audiovisuales",
	"家居厨具": "Utensilios de cocina",
	"火机烟具": "Encendedores y artículos para fumar",
	"餐具": "Vajillas",
	"烹饪厨具": "Utensilios de cocina",
	"水具酒具": "Utensilios de bebida",
	"生活日用": "Artículo de uso diario",
	"居家布艺": "Decoración de hogar",
	"床上用品": "Prendas de cama",
	"汽摩生活": "Accesorios de automóviles y motocicletas",
	"电动车": "Bicicleta eléctrica",
	"摩托车": "Motocicletas",
	"农用机械": "Maquinaria agrícola",
	"搬运设备": "Equipos de carga",
	"装饰用品": "Artículos de decoración",
	"派对用品": "Artículos para fiestas",
	"母婴": "Cuidado de madres y bebés",
	"奶粉": "Polvo de leche",
	"尿布湿巾": "Pañales y toallitas húmedas",
	"洗护用品": "Productos de higiene",
	"营养辅食": "Alimentos complementarios",
	"喂养工具": "Herramientas de alimentación",
	"欢迎访问 Go2Pie商城，查找商品": "Bienvenido a Go2Pie, buscar productos",
	"我们的服务能力": "Nuestras capacidades de servicio",
	"贸易": "Comercio",
	"自主工厂生产及国内外品牌直供": "Producción autónoma de fábricas y suministro directo de marcas nacionales e internacionales",
	"自研国际自由贸易平台Goπ": "Desarrolla autónomamente la plataforma internacional de comercio libre",
	"品牌授权及合作线下门店": "Autorización de marca y colaboración con tiendas físicas",
	"国内外各大品牌经销/代理商": "Distribuidores/Agentes de las principales marcas nacionales e internacionales",
	"国际线上交易支付管理系统": "Sistema de gestión de pagos para transacciones internacionales en línea",
	"营销": "Mercadotecnia",
	"整合营销推广解决方案": "Integrar soluciones de marketing y promoción",
	"媒体营销推广": "Promoción de marketing en medios",
	"社交平台运营": "Gestión de redes sociales",
	"电商平台运营": "Gestión de plataforma de comercio electrónico",
	"内容创意及制作": "Contenido creativo y producción",
	"物流配送": "Logística de distribución",
	"顶级物流体系及航运支持": "Sistema logístico de primer nivel y apoyo en transporte marítimo",
	"全国物流和冷链配送": "Logística nacional y distribución en cadena de frío",
	"国际供应及国际运输": "Suministro internacional y envío internacional",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "Proporcionar servicios integrales que incluyen la construcción de canales de venta, adquisiciones centralizadas para empresas y envíos directos por unidad",
	"需求反馈收集": "Recopilación de retroalimentación de necesidades",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "Podemos satisfacer todas sus necesidades, por favor deje sus necesidades y nos pondremos en contacto con usted en breve",
	"提交需求反馈表单": "Enviar el formulario de retroalimentación de la demanda",
	"集团合作伙伴": "Socios del grupo",
	"集团": "grupo",
	"合作伙伴及品牌": "Socios y marcas",
	"合作媒体": "Medios de comunicación colaboradores",
	"华汾版权所有": "Todos los derechos reservados por Huafen",

	"收起": "Ver menos",
	"展开": "Ver todo",
}