export const lang = {
	"公司介绍": "公司介绍",
	"品类介绍": "品类介绍",
	"服务能力": "服务能力",
	"需求反馈": "需求反馈",
	"合作伙伴": "合作伙伴",
	"联系我们": "联系我们",
	"全球优质供应链服务商": "全球优质供应链服务商",
	"为全球品牌和企业提供定制化产品解决方案": "为全球品牌和企业提供定制化产品解决方案",
	"华汾公司介绍": "华汾公司介绍",
	"aboutUs1": "华汾（北京）国际商贸有限公司，是一家集实业产品研发、生产、设计、全球品牌营销、进出口贸易销售为一身的国际贸易公司，并基于Web3.0去中心化的技术核心，通过全球贸易网络的数字智能化管理，为品牌和企业提供优质的互联网营销和数字化管理运营服务。",
	"依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。": "依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。",
	"公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。": "公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。",
	"我们能提供的品类": "我们能提供的品类",
	"个护清洁": "个护清洁",
	"清洁工具": "清洁工具",
	"家庭清洁": "家庭清洁",
	"衣物清洁": "衣物清洁",
	"洗发护发": "洗发护发",
	"身体护理": "身体护理",
	"纸品湿巾": "纸品湿巾",
	"口腔护理": "口腔护理",
	"驱蚊驱虫": "驱蚊驱虫",
	"食品酒饮": "食品酒饮",
	"乳饮冰品": "乳饮冰品",
	"中外名酒": "中外名酒",
	"方便速食": "方便速食",
	"粮油调味": "粮油调味",
	"咖啡": "咖啡",
	"休闲食品": "休闲食品",
	"美妆护肤": "美妆护肤",
	"面部护肤": "面部护肤",
	"香水": "香水",
	"彩妆": "彩妆",
	"家电": "家电",
	"家电配件": "家电配件",
	"厨房小电": "厨房小电",
	"清洁电器": "清洁电器",
	"个护健康": "个护健康",
	"空调": "空调",
	"冰箱": "冰箱",
	"电视": "电视",
	"厨卫大电": "厨卫大电",
	"视听影音": "视听影音",
	"家居厨具": "家居厨具",
	"火机烟具": "火机烟具",
	"餐具": "餐具",
	"烹饪厨具": "烹饪厨具",
	"水具酒具": "水具酒具",
	"生活日用": "生活日用",
	"居家布艺": "居家布艺",
	"床上用品": "床上用品",
	"汽摩生活": "汽摩生活",
	"电动车": "电动车",
	"摩托车": "摩托车",
	"农用机械": "农用机械",
	"搬运设备": "搬运设备",
	"装饰用品": "装饰用品",
	"派对用品": "派对用品",
	"母婴": "母婴",
	"奶粉": "奶粉",
	"尿布湿巾": "尿布湿巾",
	"洗护用品": "洗护用品",
	"营养辅食": "营养辅食",
	"喂养工具": "喂养工具",
	"欢迎访问 Go2Pie商城，查找商品": "欢迎访问 Go2Pie商城，查找商品",
	"我们的服务能力": "我们的服务能力",
	"贸易": "贸易",
	"自主工厂生产及国内外品牌直供": "自主工厂生产及国内外品牌直供",
	"自研国际自由贸易平台Goπ": "自研国际自由贸易平台Goπ",
	"品牌授权及合作线下门店": "品牌授权及合作线下门店",
	"国内外各大品牌经销/代理商": "国内外各大品牌经销/代理商",
	"国际线上交易支付管理系统；": "国际线上交易支付管理系统；",
	"营销": "营销",
	"整合营销推广解决方案": "整合营销推广解决方案",
	"媒体营销推广": "媒体营销推广",
	"社交平台运营": "社交平台运营",
	"电商平台运营": "电商平台运营",
	"内容创意及制作": "内容创意及制作",
	"物流配送": "物流配送",
	"顶级物流体系及航运支持": "顶级物流体系及航运支持",
	"全国物流和冷链配送": "全国物流和冷链配送",
	"国际供应及国际运输": "国际供应及国际运输",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "提供销售通路搭建、企业集采及一件代发等全方位服务",
	"需求反馈收集": "需求反馈收集",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系",
	"提交需求反馈表单": "提交需求反馈表单",
	"集团合作伙伴": "集团合作伙伴",
	"合作伙伴及品牌": "合作伙伴及品牌",
	"合作媒体": "合作媒体",
	"华汾版权所有": "华汾版权所有"
}