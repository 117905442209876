<template>
  <div id="HomePage">
    <div class="box">
      <nav
        class="navbar"
        :style="{ top: pullDownNavTop + 'px' }"
        style="display: black"
      >
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="header-nav-logo" @click="scrollToTop">
            <img src="@/assets/img/logo.png" alt="" />
          </div>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav navbar-right">
            <li
              class="item"
              @click="scrollTo(item.id)"
              :class="{ active: activeSection == item.id }"
              v-for="(item, index) in navList"
              :key="item.id"
            >
              <a href="javascript:;">
                {{ item.title }}
                <i class="underline"></i>
              </a>
            </li>
            <li class="lang" @click="showLang = !showLang">
              <p style="font-weight: bold">{{ currentLang.toUpperCase() }}</p>
              <img
                src="@/assets/img/down_jt_black.png"
                alt=""
                style="width: 8px; height: 8px"
              />

              <div class="lang-pop df-ac-jsb fd-c" v-show="showLang">
                <p
                  v-for="(item, index) in language"
                  @click="changeLang(item.lang, index)"
                  :class="{ active: item.lang == currentLang }"
                >
                  {{ item.lang.toUpperCase() }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <!-- 手机导航 -->
      <div class="mobile-nav" :style="{ top: pullDownNavTop + 'px' }">
        <div class="df-ac-jsb top">
          <div><img src="@/assets/img/logo.png" alt="" width="162px" /></div>
          <div v-if="!navOpen">
            <img
              src="@/assets/img/nav_more.png"
              class="icon"
              alt=""
              width="36px"
              @click="toggleNav"
            />
          </div>
          <div v-else>
            <img
              src="@/assets/img/nav_close.png"
              class="icon"
              alt=""
              width="36px"
              @click="toggleNav"
            />
          </div>
        </div>
        <ul>
          <li
            class="item"
            @click="scrollTo(item.id)"
            :class="{ active: activeSection == item.id }"
            v-for="(item, index) in navList"
            :key="item.id"
          >
            {{ item.title }}
          </li>
          <li class="" @click="showLang = !showLang" style="position: relative">
            <div class="df-ac-jc lang">
              <p style="font-weight: bold">{{ currentLang.toUpperCase() }}</p>
              <img
                src="@/assets/img/down_jt_black.png"
                alt=""
                style="width: 8px; height: 8px; margin-left: 7px"
              />
            </div>
            <div class="lang-pop df-ac-jsb fd-c" v-show="showLang">
              <p
                v-for="(item, index) in language"
                @click="changeLang(item.lang, index)"
                :class="{ active: item.lang == currentLang }"
              >
                {{ item.lang.toUpperCase() }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="content">
        <!-- 首页 -->
        <div class="container-fuild index">
          <div class="container wow fadeInDown">
            <div class="row">
              <div class="col-sm-12 col-md-12 df-ac-jc fd-c">
                <div class="txt1 text-center">
                  {{ $t("lang.全球优质供应链服务商") }}
                </div>
                <div class="txt2 text-center">
                  {{ $t("lang.为全球品牌和企业提供定制化产品解决方案") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 公司介绍 -->
        <div class="conatiner-fuild section about-us" id="section1">
          <div class="container wow fadeInDown df-ac-jc fd-c">
            <div class="txt1 text-center">
              {{ $t("lang.华汾公司介绍") }}
            </div>
            <img src="@/assets/img/bg2_1g.png" alt="" class="lou" />
            <div class="txt2">
              <div>
                <span>{{ $t("lang.company-profile-title1") }}</span>
                {{ $t("lang.company-profile1") }}
              </div>
              <template v-if="aboutShowToggle">
                <div>
                  <span>{{ $t("lang.company-profile-title2") }}</span>
                  {{ $t("lang.company-profile2") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title3") }}</span>
                  {{ $t("lang.company-profile3") }}
                </div>

                <div class="mb20">
                  <span>{{ $t("lang.company-profile-title4") }}</span>
                  {{ $t("lang.company-profile4") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title5") }}</span>
                  {{ $t("lang.company-profile5") }}
                </div>

                <div>
                  <span>{{ $t("lang.company-profile-title6") }}</span>
                  {{ $t("lang.company-profile6") }}
                </div>

                <div class="mb20">
                  <span>{{ $t("lang.company-profile-title7") }}</span>
                  {{ $t("lang.company-profile7") }}
                </div>
                <div>
                  <span>{{ $t("lang.company-profile-title8") }}</span>
                  {{ $t("lang.company-profile8") }}
                </div>
              </template>

              <div
                class="toggle df-ac-jc"
                @click="handleToggle"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
              >
                {{ aboutToggleText }}
                <img
                  :src="aboutToggleImg"
                  alt=""
                  :style="{
                    transform:
                      aboutShowToggle == true
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 分类 -->
        <div class="conatiner-fuild section category" id="section2">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.我们能提供的品类") }}</p>
            <div class="row">
              <div
                class="col-xs-6 col-sm-4 col-md-4 item df-ac fd-c"
                v-for="(item, index) in categoryList"
                :key="item.id"
                @mouseenter="showDescription(item, index)"
                @mouseleave="hideDescription(item, index)"
              >
                <div
                  :class="{ bortop: index == categoryIndex }"
                  class="df-ac fd-c"
                  style="width: 100%"
                >
                  <div
                    class="box"
                    :class="{ active: index == categoryIndex }"
                    :style="{
                      zIndex: index == categoryIndex ? 99 : 0,
                      position: index == categoryIndex ? 'relative' : 'static',
                    }"
                  >
                    <img :src="item.img" alt="" class="" />
                    <p class="txt1">{{ item.title }}</p>
                    <ul v-show="item.showDescription">
                      <li v-for="item2 in item.second">{{ item2 }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-style df-ac-jc">
              <a
                :href="'https://www.go2pie.com?lang=' + currentLang"
                class="df-ac-jc"
                style="color: #fff"
                target="_blank"
              >
                {{ $t("lang.欢迎访问 Go2Pie商城，查找商品") }}
                <img
                  src="@/assets/img/right_icon.png"
                  alt=""
                  class="right-icon"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- 服务能力 -->
        <div class="conatiner-fuild section service" id="section3">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.我们的服务能力") }}</p>
            <div class="row">
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_1.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.贸易") }}</p>
                  <p class="txt2">
                    {{ $t("lang.自主工厂生产及国内外品牌直供") }}
                  </p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.自研国际自由贸易平台Goπ") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.品牌授权及合作线下门店") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国内外各大品牌经销/代理商") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国际线上交易支付管理系统") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_2.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.营销") }}</p>
                  <p class="txt2">{{ $t("lang.整合营销推广解决方案") }}</p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.媒体营销推广") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.社交平台运营") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.电商平台运营") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.内容创意及制作") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 item">
                <div class="box">
                  <img src="@/assets/img/bg4_3.png" alt="" class="" />
                  <p class="txt1">{{ $t("lang.物流配送") }}</p>
                  <p class="txt2">{{ $t("lang.顶级物流体系及航运支持") }}</p>
                  <ul>
                    <li>
                      <p></p>
                      {{ $t("lang.全国物流和冷链配送") }}
                    </li>
                    <li>
                      <p></p>
                      {{ $t("lang.国际供应及国际运输") }}
                    </li>
                    <li>
                      <p></p>
                      {{
                        $t(
                          "lang.提供销售通路搭建、企业集采及一件代发等全方位服务"
                        )
                      }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 需求反馈 -->
        <div class="container-fuild section feedback" id="section4">
          <div class="container wow fadeInDown df-ac-jc fd-c">
            <p class="title">{{ $t("lang.需求反馈收集") }}</p>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <img src="@/assets/img/bg5_1.png" alt="" class="icon" />
                <p class="txt1">
                  {{
                    $t(
                      "lang.我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系"
                    )
                  }}
                </p>
                <div class="btn-style df-ac-jc">
                  <a
                    :href="
                      'https://www.go2pie.com/pages/order/wish/submit?lang=' +
                      currentLang
                    "
                    class="df-ac-jc"
                    style="color: #fff"
                    target="_blank"
                  >
                    {{ $t("lang.提交需求反馈表单") }}
                    <img
                      src="@/assets/img/right_icon.png"
                      alt=""
                      class="right-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 合作伙伴 -->
        <div class="container-fuild section cooperation" id="section5">
          <div class="container wow fadeInDown hidden-xs">
            <p class="title">{{ $t("lang.集团合作伙伴") }}</p>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.集团") }}
              </p>
              <img src="@/assets/img/bg6_1.png" alt="" class="img1" />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作伙伴及品牌") }}
              </p>
              <img src="@/assets/img/bg6_2g.png" alt="" class="img2" />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作媒体") }}
              </p>
              <img src="@/assets/img/bg6_3.png" alt="" class="img3" />
            </div>
          </div>

          <div class="container visible-xs">
            <p class="title">{{ $t("lang.集团合作伙伴") }}</p>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.集团") }}
              </p>
              <img
                src="@/assets/img/bg6_m1.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作伙伴及品牌") }}
              </p>
              <img
                src="@/assets/img/bg6_m2.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
            <div class="bracd">
              <p class="df-ac">
                <i></i>
                {{ $t("lang.合作媒体") }}
              </p>
              <img
                src="@/assets/img/bg6_m3.png"
                alt="Responsive image"
                class="img-responsive"
              />
            </div>
          </div>
        </div>

        <!-- 联系我们 -->
        <div class="container-fuild section contact-us" id="section6">
          <div class="container wow fadeInDown">
            <p class="title">{{ $t("lang.联系我们") }}</p>
            <img src="@/assets/img/bg7_bg1.png" alt="" class="bg visible-xs" />
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_1.png" alt="" class="img1" />
              <p>
                9th Floor, T1 Building, Poly International Plaza, Chaoyang
                District, Beijing, China.
              </p>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_2.png" alt="" class="img1" />
              <p>0086-400-850-9883</p>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 item df fd-c">
              <img src="@/assets/img/bg7_3.png" alt="" class="img1" />
              <p>HFIT@huafenjiu.net</p>
            </div>
          </div>
        </div>

        <!-- 底部-->
        <div class="conatiner-fuild footer">
          <div class="container hidden-xs wow fadeInDown">
            <div class="txt1 df-ac fl">
              <img src="@/assets/img/icon.png" alt="" class="img1" />
              Huafen (Beijing) International Trade Co., Ltd
            </div>
            <div class="txt2 fr">
              © 2024 - 2025 {{ $t("lang.华汾版权所有") }}
            </div>
          </div>
          <div class="container visible-xs wow fadeInDown">
            <img
              src="@/assets/img/icon.png"
              alt=""
              class="img1"
              style="
                width: 22px;
                height: 32px;
                margin: auto;
                margin-top: 28px;
                margin-bottom: 12px;
              "
            />
            <div class="txt1">
              Huafen (Beijing) International Trade Co., Ltd
            </div>
            <div class="txt2">© 2024 - 2025 {{ $t("lang.华汾版权所有") }}</div>
          </div>
        </div>

        <!-- 右侧固定 -->
        <div
          class="right-fixed df-ac hidden-xs"
          @mouseenter="showFixedBtn"
          @mouseleave="hideFixedBtn"
          v-show="fixedBtnRightShow"
        >
          <div
            class="open df-ac"
            :style="{
              right: fixedBtnRight,
              width: fixedBtnWidth,
              zIndex: fixedBtnZindex,
            }"
          >
            <a
              :href="
                'https://www.go2pie.com/pages/order/wish/submit?lang=' +
                currentLang
              "
              class="df-ac-jc"
              style="color: #fff"
              target="_blank"
            >
              <img src="@/assets/img/fixed_right2.png" alt="" class="img1" />
              <p class="txt">
                {{ $t("lang.提交需求反馈表单") }}
              </p>
              <img src="@/assets/img/fixed_right3.png" alt="" class="img2" />
            </a>
          </div>
          <img src="@/assets/img/fixed_right1.png" alt="" class="img1" />
        </div>

        <div class="right-fixed visible-xs">
          <a
            :href="
              'https://www.go2pie.com/pages/order/wish/submit?lang=' +
              currentLang
            "
            class="df-ac-jc"
            style="color: #fff"
            target="_blank"
          >
            <img src="@/assets/img/fixed_right1.png" alt="" class="img1" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "HomePage",
  data() {
    return {
      activeSection: "", // 当前激活的导航索引
      navList: [
        {
          id: "section1",
          title: this.$t("lang.公司介绍"),
        },
        {
          id: "section2",
          title: this.$t("lang.品类介绍"),
        },
        {
          id: "section3",
          title: this.$t("lang.服务能力"),
        },
        {
          id: "section4",
          title: this.$t("lang.需求反馈"),
        },
        {
          id: "section5",
          title: this.$t("lang.合作伙伴"),
        },
        {
          id: "section6",
          title: this.$t("lang.联系我们"),
        },
      ],
      // 分类
      categoryList: [
        {
          id: "1",
          title: this.$t("lang.个护清洁"),
          showDescription: false,
          img: require("@/assets/img/bg3_1.png"),
          second: [
            this.$t("lang.清洁工具"),
            this.$t("lang.家庭清洁"),
            this.$t("lang.衣物清洁"),
            this.$t("lang.洗发护发"),
            this.$t("lang.身体护理"),
            this.$t("lang.纸品湿巾"),
            this.$t("lang.口腔护理"),
            this.$t("lang.驱蚊驱虫"),
          ],
        },
        {
          id: "2",
          title: this.$t("lang.食品酒饮"),
          showDescription: false,
          img: require("@/assets/img/bg3_2.png"),
          second: [
            this.$t("lang.乳饮冰品"),
            this.$t("lang.中外名酒"),
            this.$t("lang.方便速食"),
            this.$t("lang.粮油调味"),
            this.$t("lang.咖啡"),
            this.$t("lang.休闲食品"),
          ],
        },
        {
          id: "3",
          title: this.$t("lang.美妆护肤"),
          showDescription: false,
          img: require("@/assets/img/bg3_3.png"),
          second: [
            this.$t("lang.面部护肤"),
            this.$t("lang.香水"),
            this.$t("lang.彩妆"),
          ],
        },
        {
          id: "4",
          title: this.$t("lang.汽摩生活"),
          showDescription: false,
          img: require("@/assets/img/bg3_4.png"),
          second: [this.$t("lang.电动车"), this.$t("lang.摩托车")],
        },
        {
          id: "5",
          title: this.$t("lang.家电"),
          showDescription: false,
          img: require("@/assets/img/bg3_5.png"),
          second: [
            this.$t("lang.家电配件"),
            this.$t("lang.厨房小电"),
            this.$t("lang.清洁电器"),
            this.$t("lang.个护健康"),
            this.$t("lang.空调"),
            this.$t("lang.冰箱"),
            this.$t("lang.电视"),
            this.$t("lang.厨卫大电"),
            this.$t("lang.视听影音"),
          ],
        },
        {
          id: "6",
          title: this.$t("lang.家居厨具"),
          showDescription: false,
          img: require("@/assets/img/bg3_6.png"),
          second: [
            this.$t("lang.火机烟具"),
            this.$t("lang.餐具"),
            this.$t("lang.烹饪厨具"),
            this.$t("lang.水具酒具"),
            this.$t("lang.生活日用"),
            this.$t("lang.居家布艺"),
            this.$t("lang.床上用品"),
          ],
        },
        {
          id: "7",
          title: this.$t("lang.农用机械"),
          showDescription: false,
          img: require("@/assets/img/bg3_7.png"),
          second: [this.$t("lang.搬运设备")],
        },
        {
          id: "8",
          title: this.$t("lang.装饰用品"),
          showDescription: false,
          img: require("@/assets/img/bg3_8.png"),
          second: [this.$t("lang.派对用品")],
        },
        {
          id: "9",
          title: this.$t("lang.母婴"),
          showDescription: false,
          img: require("@/assets/img/bg3_9.png"),
          second: [
            this.$t("lang.奶粉"),
            this.$t("lang.尿布湿巾"),
            this.$t("lang.洗护用品"),
            this.$t("lang.营养辅食"),
            this.$t("lang.喂养工具"),
          ],
        },
      ],
      language: [
        {
          lang: "es",
        },
        {
          lang: "en",
        },
      ],
      currentLang: localStorage.getItem("currentLang") || "es",
      showLang: false,
      categoryIndex: -1,
      pullDownNavTop: 15,
      fixedBtnRight: "-103px",
      fixedBtnWidth: "80px",
      fixedBtnZindex: 99,
      fixedBtnRightShow: true,
      isPc: false,
      navOpen: false,
      oldScrollTop: 0,
      aboutShowToggle: false,
      aboutToggleText: this.$t("lang.展开"),
      aboutToggleImg: require("@/assets/img/arrows.png"),
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
    $(".navbar-collapse a").on("click", function () {
      $(".navbar-collapse").collapse("hide");
    });
    $(".mobile-nav ul").hide();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen;
      $(".mobile-nav ul").toggle("slow");
    },
    handleResize() {
      if (window.innerWidth <= 750) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.pullDownNavTop = this.isMobile ? 0 : 15;
    },
    changeLang(lang, index) {
      this.currentLang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("currentLang", lang);
      window.location.reload();
    },
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    },
    handleScroll(e) {
      this.showLang = false;
      const sections = document.querySelectorAll(".section");
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 10 && rect.bottom > 0) {
          currentSection = section.id;
        }
      });
      this.activeSection = currentSection;

      this.pullDownNavTop = -120;
      this.showTimer = setTimeout(() => {
        this.pullDownNavTop = this.isMobile ? 0 : 15;
      }, 800);

      if (window.scrollY >= 500) {
        this.fixedBtnRightShow = true;
      } else {
        this.fixedBtnRightShow = true;
      }
      this.navOpen = false;
      $(".mobile-nav ul").hide();
    },
    // 分类
    showDescription(item, index) {
      item.showDescription = true;
      this.categoryIndex = index;
    },
    hideDescription(item) {
      this.categoryIndex = -1;
      item.showDescription = false;
    },
    showFixedBtn() {
      this.fixedBtnRight = "-103px";
      this.fixedBtnWidth = "520px";
      this.fixedBtnZindex = 999;
    },
    hideFixedBtn() {
      this.fixedBtnRight = "-103px";
      this.fixedBtnWidth = "80px";
      this.fixedBtnZindex = 99;
    },
    scrollToTop() {
      window.scrollTo(0, 0); // 滚动到页面的左上角
    },
    handleToggle() {
      this.aboutShowToggle = !this.aboutShowToggle;
      this.aboutToggleText = this.aboutShowToggle
        ? this.$t("lang.收起")
        : this.$t("lang.展开");
    },
    handleMouseEnter() {
      this.aboutToggleImg = require("../assets/img/arrows_white.png");
    },
    handleMouseLeave() {
      this.aboutToggleImg = require("../assets/img/arrows.png");
    },
  },
};
</script>
<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}
/* 整体盒子 */
#HomePage {
  width: 100%;
  background: #fff;
}
/* 当导航被点亮后改变颜色 */
.header-nav-wrapper .active {
  color: #847ec3;
}
.btn-style {
  width: max-content;
  background: #c39067;
  border-radius: 40px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: auto;
  height: 80px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #000000;
    transition: all ease 0.6s;
    border: 1px solid #ffffff;
  }
}
.right-icon {
  width: 72px;
  height: 72px;
  margin-left: 22px;
  position: absolute;
  right: 4px;
}
.title {
  font-size: 48px;
  padding-top: 136px;
  font-family: ssstBold;
}
.section {
  transition: all ease 0.6s;
  margin-top: -80px;
}
/* 导航栏 */
.navbar {
  padding: 0 24px;
  height: 82px;
  line-height: 82px;
  border-radius: 16px;
  background: #fff;
  margin: 0 15px;
  width: calc(100% - 30px);
  position: fixed;
  top: 15px;
  z-index: 10;
  transition: all 0.5s;
  z-index: 999;
  .nav {
    padding-top: 31px;
    a {
      padding: 0;
      padding-bottom: 8px;
    }
  }
  .header-nav-logo {
    width: 200px;
    height: 62px;
    float: left;
    margin-top: 10px;
    cursor: pointer;
    img {
      width: 200px;
      height: 62px;
    }
  }
  ul {
    li {
      margin-left: 34px;
      a {
        color: #161c2d;
        font-size: 15px;
        padding-bottom: 8px;
        position: relative;
        cursor: pointer;
      }
      i {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 0;
        height: 2px;
        opacity: 0;
        transition: all 0.6s ease;
        background-color: #000;
      }
    }
    li > a:focus {
      background: transparent;
    }
    li.active > a {
      font-weight: bold;
      text-decoration: none;
      border-bottom: 2px solid #000000;
      background: transparent;
    }
    li > a:hover .underline {
      opacity: 1;
      width: 100%;
      left: 0;
    }
    li > a:hover {
      text-decoration: none;
      background: transparent;
    }
    .lang {
      width: 56px;
      height: 26px;
      border-radius: 13px;
      border: 1px solid #000000;
      text-align: center;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -3px;
      cursor: pointer;
      p {
        color: #161c2d;
      }
      img {
        margin-left: 7px;
      }
      .active {
        font-weight: bold;
      }
    }
    .lang-pop {
      width: 56px;
      height: 80px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
      border-radius: 10px;
      position: absolute;
      top: 30px;
      padding: 10px 0;
    }
  }
  .navbar-toggle {
    background: #000;
    margin-top: 24px;
    .icon-bar {
      background: #fff;
    }
  }
}
// 首页
.index {
  transition: all ease 0.6s;
  background: url(../../src/assets/img/bg1g.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  height: 1160px;
  padding-top: 400px;
  color: #fff;
  top: 0;
  .row {
    margin: 0 70px;
  }
  .txt1 {
    font-size: 66px;
    font-weight: bold;
    font-family: ssstBold;
    line-height: 80px;
  }
  .txt2 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 20px;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    line-height: 30px;
  }
}
.mb20 {
  margin-bottom: 40px;
}
// 公司介绍
.about-us {
  background: url(../../src/assets/img/bg2g.png) no-repeat;
  background-size: 100% 100%;
  // height: 969px;
  color: #161c2d;
  padding-top: 158px;
  padding-bottom: 257px;
  .toggle {
    width: max-content;
    padding: 0 18px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: bold;
    img {
      width: 12px;
      // height: 12px;
      margin-left: 13px;
    }
    &:hover {
      color: #fff;
      background: #000000;
      transition: all ease 0.6s;
      border: 1px solid #ffffff;
    }
  }
  .lou {
    width: 380px;
    margin: 56px 0;
  }
  .txt1 {
    font-family: ssstBold;
    font-weight: bold;
    font-size: 47px;
    color: #161c2d;
    line-height: 58px;
    width: 100%;
  }
  .txt2 {
    font-weight: 400;
    font-size: 16px;
    color: #161c2d;
    line-height: 32px;
    text-align: left;
    span {
      font-weight: bold;
    }
  }
}
// 分类
.category {
  // background: url(../../src/assets/img/bg3.png) no-repeat;
  // background-size: 100% 100%;
  background: #fff;
  color: #161c2d;
  padding-bottom: 160px;
  .btn-style {
    padding: 4px 120px 4px 30px;
    margin-top: 30px;
  }
  .title {
    margin-bottom: 83px;
  }
  .item {
    border-radius: 8px;
    margin-bottom: 24px;
    height: 180px;
    cursor: pointer;
    .box {
      background: linear-gradient(180deg, #f4f7fa 0%, #ffffff 100%);
      width: 100%;
      padding: 24px 24px 36px 24px;
      border-radius: 8px;
      position: relative;
    }
    img {
      width: 80px;
      height: 80px;
      margin: auto;
    }
    .txt1 {
      font-size: 14px;
      margin-top: 24px;
    }
    ul {
      margin-top: 26px;
      line-height: 18px;
      font-size: 12px;
      text-align: center;
      transition: all ease 0.6s;
      opacity: 0.7;
    }
  }
  .bortop {
    border-top: 4px solid #c39067;
    border-radius: 8px;
  }
  .active {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    padding-bottom: 18px !important;
    transition: all ease 0.6s;
    // border-top: 4px solid #c39067;
    .txt1 {
      font-weight: 600;
    }
  }
}

// 服务能力
.service {
  background: url(../../src/assets/img/bg4g.png) no-repeat;
  background-size: 100% 100%;
  // height: 1160px;
  color: #161c2d;
  padding-bottom: 226px;
  .title {
    margin-bottom: 130px;
  }
  .item {
    border-radius: 8px;
    .box {
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(249, 249, 245, 0) 100%
      );
      width: 100%;
      height: 100%;
      border-radius: 8px;
      padding: 50px 30px 46px 30px;
    }

    img {
      width: 32px;
      height: 32px;
      margin: auto;
    }
    .txt1 {
      font-size: 24px;
      margin-top: 22px;
      font-weight: bold;
    }
    .txt2 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
    }
    ul {
      margin-top: 37px;
      li {
        font-size: 16px;
        line-height: 24px;
        color: #161c2d;
        text-align: left;
        margin-bottom: 24px;
        opacity: 0.7;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          width: 26px;
          height: 2px;
          background: #c39067;
          margin-bottom: 6px;
        }
      }
    }
  }
}

// 反馈
.feedback {
  background: url(../../src/assets/img/bg5g.png) no-repeat;
  background-size: 100% 100%;
  // height: 1160px;
  color: #fff;
  padding-bottom: 266px;
  .icon {
    width: 144px;
    height: 144px;
    margin: 76px auto;
  }
  .txt1 {
    font-weight: 500;
    font-size: 28px;
    line-height: 48px;
    width: 80%;
    margin: auto;
  }
  .btn-style {
    margin-top: 128px;
    padding: 4px 138px 4px 100px;
  }
}
// 合作伙伴
.cooperation {
  // background: url(../../src/assets/img/bg6.png) no-repeat;
  // background-size: 100% 100%;
  background: #fff;
  // height: 1357px;
  padding-bottom: 220px;
  .title {
    margin-bottom: 80px;
  }
  .bracd {
    color: #adadad;
    margin-bottom: 68px;
    p {
      margin-bottom: 48px;
    }
    i {
      width: 8px;
      height: 8px;
      background: #c39067;
      display: block;
      border-radius: 100px;
      margin-right: 12px;
    }
  }
  .img1 {
    width: 783px;
    height: 48px;
  }
  .img2 {
    width: 1201px;
    height: 436px;
  }
  .img3 {
    width: 1200px;
    height: 52px;
  }
}
// 联系我们
.contact-us {
  background: url(../../src/assets/img/bg7g.png) no-repeat;
  background-size: 100% 100%;
  height: 1080px;
  .title {
    margin-bottom: 257px;
  }
  .item {
    padding: 0 40px;
    &:last-child {
      margin-right: 0;
    }
    img {
      width: 54px;
      height: 54px;
      margin: auto;
      margin-bottom: 26px;
    }
    p {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

.footer {
  height: 100px;
  background: #ffffff;
  .container {
    line-height: 100px;
  }
  .txt1 {
    font-weight: 600;
    font-size: 18px;
    img {
      width: 20px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .txt2 {
    font-weight: 400;
    font-size: 15px;
  }
}
.right-fixed {
  position: fixed;
  bottom: 100px;
  right: 24px;
  cursor: pointer;
  .img1 {
    width: 80px;
    cursor: pointer;
    z-index: 99;
  }
  .img2 {
    width: 54px;
    margin-left: 30px;
  }
  .txt {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }
  .open {
    width: 80px;
    height: 80px;
    background: #c39067;
    border-radius: 40px;
    border: 2px solid #c39067;
    color: #fff;
    margin-right: 24px;
    position: relative;
    transition: all 0.6s ease;
    padding-right: 22px;
    overflow: hidden;
    // z-index: 999;
  }
}
.mobile-nav {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 0 16px;
  transition: all 0.6s ease;
  .top {
    height: 82px;
    cursor: pointer;
  }
  .icon {
    transition: all 0.6s ease;
  }
  ul {
    padding-bottom: 24px;
    li {
      text-align: left;
      line-height: 36px;
      cursor: pointer;
    }
  }
  .lang {
    width: 56px;
    height: 26px;
    border-radius: 13px;
    border: 1px solid #000000;
    margin-top: 24px;
  }
  .lang-pop {
    width: 56px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    position: absolute;
    top: -52px;
    left: 66px;
  }
  .active {
    font-weight: bold;
  }
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 0px;
}
@media (max-width: 1370px) {
  .navbar {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}
@media (min-width: 1370px) {
  .navbar {
    display: block;
  }
  .mobile-nav {
    display: none;
  }
}
/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  .title {
    font-size: 32px;
    padding-top: 64px;
  }
  .btn-style {
    min-width: auto;
    width: 98%;
    height: 68px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    padding-left: 16px;
  }
  .section {
    border-radius: 24px;
    margin-top: -32px;
    padding-bottom: 80px;
    height: auto;
  }
  .right-icon {
    width: 60px;
    height: 60px;
  }
  .index {
    height: 813px;
    padding-top: 160px;
    background: url(../../src/assets/img/bg_m1g.png) no-repeat;
    background-size: 100% 100%;
    .txt1 {
      width: auto;
      font-size: 40px;
      line-height: 60px;
    }
    .txt2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 20px;
      width: auto;
    }
    .row {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .about-us {
    // height: 1156px;
    padding-top: 64px;
    background: #f4f7fa;
    .lou {
      width: 244px;
      margin: auto;
    }
    .txt1 {
      width: auto;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 48px;
      text-align: center;
    }
    .txt2 {
      width: auto;
      font-size: 14px;
      line-height: 28px;
      margin-top: 32px;
    }
  }
  .category {
    // height: 1148px;
    background: #ffffff;
    .row {
      padding: 0 15px;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // flex-wrap: wrap;
      .col-xs-6 {
        width: 48%;
      }
    }
    .box {
      // padding: 24px 15px !important;
    }
    .item {
      height: 203px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 16px;
      &:nth-child(2n) {
        float: right;
      }
      .txt1 {
        overflow: hidden;
      }
    }
    .btn-style {
      padding: 0 82px 0 16px;
    }
  }
  // 服务能力
  .service {
    // height: 1850px;
    background: #f9f9f5;
    .title {
      margin-bottom: 48px;
    }
  }
  // 反馈
  .feedback {
    // height: 780px;
    background: #111112;
    .icon {
      margin: 48px auto;
    }
    .btn-style {
      margin-top: 32px;
      padding: 0 82px 0 16px;
    }
  }
  .cooperation {
    // height: 1148px;
    background: #ffffff;
    .title {
      margin-bottom: 48px;
    }
    .bracd {
      color: #adadad;
      margin-bottom: 40px;
      p {
        margin-bottom: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // 联系我们
  .contact-us {
    // height: 670px;
    background: #f4f7fa;
    position: relative;
    .bg {
      height: 132px;
      position: absolute;
      top: 160px;
    }
    .title {
      margin-bottom: 50px;
    }
    .item {
      margin-bottom: 36px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer {
    // height: 166px;
    border-radius: 0;
    padding-bottom: 0;
    .container {
      line-height: initial;
    }
    .txt2 {
      margin-top: 16px;
      padding-bottom: 34px;
    }
  }
  .right-fixed {
    bottom: 60px;
    right: 16px;
    .open {
      bottom: 100px;
      width: calc(100vw - 32px);
    }
    .txt {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .navbar {
    margin: 0;
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    .header-nav-logo {
      margin-left: 15px;
    }
    .navbar-collapse {
      background: #fff;
      margin-right: -16px;
      margin-left: -16px;
    }
    .lang {
      margin: auto;
      margin-bottom: 10px;
    }
    ul {
      .lang-pop {
        top: -53px;
        left: 68px;
      }
      li {
        margin-left: 0;
        a {
          padding-bottom: 20px !important;
        }
      }
      li.active > a {
        border-bottom: none;
      }
      li > a:hover .underline {
        opacity: 0;
        width: 100%;
        left: 0;
      }
    }
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
}

@media screen and (max-width: 997px) {
}
</style>
