export const lang = {
	"中文": "英文",
	"公司介绍": "Company introduction",
	"品类介绍": "Category introduction",
	"服务能力": "Service capacity",
	"需求反馈": "Feedback on requirements",
	"合作伙伴": "Partners",
	"联系我们": "Contact us",
	"全球优质供应链服务商": "Global high-quality supply chain service provider",
	"为全球品牌和企业提供定制化产品解决方案": "Provide customized product solutions for global brands and enterprises",


	"华汾公司介绍": "Introduction to Huafen (Beijing) International Trade Co., Ltd.",
	"aboutUs1": "Huafen (Beijing) International Trade Co., Ltd. integrates industrial product research and development, production, design, global brand marketing, and import and export trade sales into one international trade company. Leveraging the decentralized technology core of Web 3.0, it offers brands and enterprises high-quality internet marketing and digital management operation services through the intelligent digital management of the global trade network.",
	"依托全国航运优势资源及全国89座城市、90座机场、覆盖超过90%的城市地标⼴告大屏资源，全方位触达中国14亿人口，为品牌提升知名度。我们以商品为媒介，秉承着科技向上的理念，为全球消费者带来具有创新的、高品质的、国际文化意义的全新生活方式商品，并向世界展示中国商贸全新发展与创新的力量。": "Relying on national shipping advantages, along with advertising in 89 cities and 90 airports that cover over 90% of urban landmarks, we reach across to China's population of 1.4 billion, enhancing brand visibility. Utilizing products as a medium and adhering to the philosophy of technological advancement, we bring innovative, high-quality lifestyle products with international cultural significance to global consumers, showcasing the new development and innovative strength of China's trade to the world.",
	"公司旗下拥有自有品牌、国际授权品牌、代理品牌三大系列，拥有产品研发、生产、销售、物流、营销的完整产业链体系。": "The company owns three series: proprietary brands, internationally authorized brands, and agency brands, and has established a complete industrial chain encompassing product research and development, production, sales, logistics, and marketing.",



	"company-profile-title1":"Huafen (Beijing) International Trade Co., Ltd. ",
	"company-profile1":"is a comprehensive international trade enterprise that merges industrial product R&D, production, design, global brand marketing, and import-export sales into a cohesive operation. Our unique approach leverages Web 3.0 decentralized technology to provide top-tier internet marketing and digital management services. We excel in orchestrating intelligent digital strategies across a global trade network, delivering exceptional value to brands and enterprises.",

	"company-profile-title2":"Strategic Advantage: ",
	"company-profile2":"We capitalize on national shipping advantages and a robust advertising network spanning 89 cities and 90 airports, effectively covering over 90% of urban landmarks. This extensive reach allows us to connect with China’s vast population of 1.4 billion, significantly enhancing brand visibility and impact. ",

	"company-profile-title3":"Innovative Product Offerings: ",
	"company-profile3":"At Huafen, we pride ourselves on driving technological advancement and innovation. We introduce high-quality lifestyle products with international cultural significance to global consumers, showcasing China’s trade development and innovative capabilities on the world stage. ",

	"company-profile-title4":"Our Product Series:",
	"company-profile4":"Huafen manages three distinct product series: ",


	"company-profile-title5":"1. Proprietary Brands ",
	"company-profile5":" - Brands developed and owned by Huafen, reflecting our commitment to innovation and quality. ",

	"company-profile-title6":"2. Internationally Authorized Brands",
	"company-profile6":" - Globally recognized brands for which we hold distribution rights, ensuring quality and authenticity. ",

	"company-profile-title7":"3. Agency Brands",
	"company-profile7":" - Brands we represent and market, leveraging our expertise to expand their reach and impact. ",

	"company-profile-title8":"Comprehensive Industrial Chain: ",
	"company-profile8":"Our company boasts a complete industrial chain that integrates product R&D, production, sales, logistics, and marketing. This holistic approach ensures seamless operations and exceptional service, aligning with our goal of delivering superior products and solutions to our clients and partners worldwide.",


	





	"我们能提供的品类": "Categories We Can Offer",
	"个护清洁": "Personal Care and Cleaning",
	"清洁工具": "Cleaning Tools",
	"家庭清洁": "Household Cleaning",
	"衣物清洁": "Laundry Cleaning",
	"洗发护发": "Hair Care",
	"身体护理": "Body Care",
	"纸品湿巾": "Paper Products and Wet Wipes",
	"口腔护理": "Oral Care",
	"驱蚊驱虫": "Mosquito and Pest Control",
	"食品酒饮": "Food and Beverages",
	"乳饮冰品": "Dairy and Frozen Products",
	"中外名酒": "Domestic and Foreign Liquor",
	"方便速食": "Instant Food",
	"粮油调味": "Grains, Oils, and Seasonings",
	"咖啡": "Coffee",
	"休闲食品": "Snacks",
	"美妆护肤": "Beauty and Skincare",
	"面部护肤": "Facial Skincare",
	"香水": "Perfume",
	"彩妆": "Makeup",
	"家电": "Home Appliances",
	"家电配件": "Appliance Accessories",
	"厨房小电": "Kitchen Small Appliances",
	"清洁电器": "Cleaning Appliances",
	"个护健康": "Personal Care and Health",
	"空调": "Air Conditioning",
	"冰箱": "Refrigerator",
	"电视": "Television",
	"厨卫大电": "Large Kitchen and Bathroom Appliances",
	"视听影音": "Audio and Video",
	"家居厨具": "Home and Kitchenware",
	"火机烟具": "Lighters and Smoking Accessories",
	"餐具": "Tableware",
	"烹饪厨具": "Cooking Utensils",
	"水具酒具": "Drinkware and Barware",
	"生活日用": "Daily Necessities",
	"居家布艺": "Home Textiles",
	"床上用品": "Bedding",
	"汽摩生活": "Automotive and Motorcycle Life",
	"电动车": "Electric Vehicles",
	"摩托车": "Motorcycles",
	"农用机械": "Agricultural Machinery",
	"搬运设备": "Handling Equipment",
	"装饰用品": "Decorative Items",
	"派对用品": "Party Supplies",
	"母婴": "Maternal and Infant",
	"奶粉": "Baby Formula",
	"尿布湿巾": "Diapers and Wipes",
	"洗护用品": "Personal Care Products",
	"营养辅食": "Nutritional Supplements",
	"喂养工具": "Feeding Tools",
	"欢迎访问 Go2Pie商城，查找商品": "Welcome to Go2Pie Mall, where you can find products",
	"我们的服务能力": "Our Service Capabilities",
	"贸易": "Trade",
	"自主工厂生产及国内外品牌直供": "Self-owned factory production and direct supply from domestic and international brands",
	"自研国际自由贸易平台Goπ": "Self-developed international free trade platform Goπ",
	"品牌授权及合作线下门店": "Brand authorization and cooperation with offline stores",
	"国内外各大品牌经销/代理商": "Distributors/agents of major domestic and international brands",
	"国际线上交易支付管理系统": "International online transaction payment management system",
	"营销": "Marketing",
	"整合营销推广解决方案": "Integrated marketing promotion solutions",
	"媒体营销推广": "Media marketing promotion",
	"社交平台运营": "Social media platform operations",
	"电商平台运营": "E-commerce platform operations",
	"内容创意及制作": "Content creation and production",
	"物流配送": "Logistics and distribution",
	"顶级物流体系及航运支持": "Top-tier logistics system and shipping support",
	"全国物流和冷链配送": "Nationwide logistics and cold chain distribution",
	"国际供应及国际运输": "International supply and transportation",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "Offering full-service services including sales channel development, bulk procurement, and dropshipping",
	"需求反馈收集": "Collection of demand feedback",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "We can meet all your needs. Please leave your requirements, and we will contact you promptly",
	"提交需求反馈表单": "Submit the demand feedback form",
	"集团合作伙伴": "Group Partners",
	"集团": "Group",
	"合作伙伴及品牌": "Partners and Brands",
	"合作媒体": "Partner Media",
	"华汾版权所有": "All Rights Reserved By Huafen",

	"收起": "Put it away",
	"展开": "Open",

}